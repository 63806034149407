<template>
  <Slider></Slider>
</template>

<script>
import './../node_modules/reset-css/reset.css';
import Slider from "@/components/Slider";

export default {
  name: 'App',

  components: {
    Slider
  }
}
</script>

<style lang="scss">


@font-face {
  font-family: 'sofia pro reg';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('./assets/fonts/Sofia-Pro-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'sofia pro';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('./assets/fonts/sofiapro-light.ttf') format('truetype');
}
@font-face {
  font-family: 'sofia pro bold';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('./assets/fonts/Sofia-Pro-Bold.ttf') format('truetype');
}

$primary-color: #015DF0;
$text-color: #031143;

html, body {
  font-family: 'sofia pro', 'Roboto', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  color: $text-color;
}



h1{
  font-weight: 400;
}

</style>
