<template>
  <div class="container" v-bind:class="bgClass">
    <div class="header">
      <div class="logo">

      </div>
    </div>
    <div class="content">
      <div class="inputs">
        <div class="title">
          Calculate
        </div>
        <div class="calculator">
          <div>
            <div class="caption">Team Velocity</div>
            <input type="number" v-model="velocity" min="1" max="100" placeholder="velocity"
                   v-on:change="calculateShippingDate()"/>
          </div>
          <div>
            <div class="caption">Remaining SPs</div>
            <input type="number" v-model="estimatedWork" min="1" max="100" placeholder="velocity"
                   v-on:change="calculateShippingDate()"/>
          </div>
        </div>

        <div class="sprints">
          <div class="caption">Sprint Length</div>

          <div class="selectable-values">
            <div v-on:click="setSprintLength(1)" v-bind:class="selectedWeek(1)">1 week</div>
            <div v-on:click="setSprintLength(2)" v-bind:class="selectedWeek(2)">2 week</div>
            <div v-on:click="setSprintLength(3)" v-bind:class="selectedWeek(3)">3 week</div>
            <div v-on:click="setSprintLength(4)" v-bind:class="selectedWeek(4)">4 week</div>
          </div>
        </div>

        <div class="caption">
          Capacity: {{ assignedCapacity }}%
        </div>

        <div>
          <input v-bind:disabled="sliderDisabled()" type="range" min="1" max="100" class="slider"
                 v-on:change="calculateShippingDate()" v-model="assignedCapacity">
        </div>


        <div class="ecd" v-if="finishDay">
          ECD day is
          <div class="finish-day">
            {{ finishDay }}
          </div>
        </div>
        <div class="remaining" v-if="finishDay">
          {{ remainingDays }} day{{ remainingDays !== 1 ? 's' : '' }} remaining
        </div>
      </div>
      <div class="text">
        management is doing things right; leadership is doing the right thing.
      </div>
    </div>
  </div>

  <footer>
    <a href="https://scrummastersuli.com/" target="_blank">
      <img src="https://scrummastersuli.com/wp-content/uploads/2019/07/PRODUCT_OWNERS_LOGO_WEB_favicon.png">
    </a>
    <div><a href="https://scrummastersuli.com/" target="_blank">Scrum Master Suli</a> ❤️ <a
        href="https://www.linkedin.com/in/peter-hegedus-374ba880" target="_blank">Péter Hegedüs</a></div>

  </footer>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Slider',
  data() {
    return {
      velocity: 3,
      estimatedWork: 31,
      assignedCapacity: 50,
      finishDay: '',
      remainingDays: 0,
      bgClass: 'level-3',
      sprintLength: 2
    }
  },
  props: {},
  methods: {
    calculateShippingDate: function () {
      this.remainingDays = Math.ceil((100 / this.assignedCapacity) * this.estimatedWork / this.velocity * this.sprintLength * 7);
      this.finishDay = moment().add(this.remainingDays, 'day').format('YYYY.MM.DD.');

      this.bgClass = 'level-' + Math.ceil(this.assignedCapacity / 20);
    },
    moment: function () {
      return moment();
    },
    sliderDisabled: function () {
      return !(this.velocity > 0 && this.assignedCapacity > 0);
    },
    setSprintLength(value) {
      this.sprintLength = value;
      this.calculateShippingDate();
    },
    selectedWeek(value) {
      return this.sprintLength === value ? 'selected' : '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.container {
  height: 100vh;
  background: url("./../../public/bg.svg") center bottom no-repeat;
  padding: 100px 16px 16px 16px;
  box-sizing: border-box;
  background-size: contain;

  @media  (min-width: 800px) {
    padding: 200px 16px 16px 16px;
  }

  input[type="range"] {
    width: 90%;
  }

  .header {
    height: 30px;
    width: 90%;
    position: fixed;
    top: 0;
    padding: 12px 24px;

    .logo {
      max-width: 1200px;
      background: url("./../../public/slider.svg") center left no-repeat;
      height: 30px;
      margin: auto;
    }

  }

  .content {
    display: flex;
    max-width: 1200px;
    margin: auto;
    flex-direction: column-reverse;

    @media  (min-width: 800px) {
      flex-direction: row;
    }

    .text {
      padding: 20px;
      font-weight: bold;
      font-size: 32px;
      max-width: 400px;
      line-height: 40px;
      text-align: center;
      margin: auto;

      @media  (min-width: 800px) {
       text-align: left;
        font-size: 40px;
        line-height: 50px;
      }

      @media  (min-width: 1200px) {
        font-size: 64px;
        line-height: 72px;
        max-width: 590px;
        padding: 50px;
      }
    }

    .calculator {
      margin: auto;
      display: flex;
      padding-bottom: 16px;
      justify-content: space-between;

      > div {
        flex: 1;
      }
    }
  }


  .inputs {
    background-color: rgba(white, 0.8);
    padding: 24px;
    margin: auto;
    box-shadow: 0 6px 22px rgba(0, 0, 0, 0.12);
    width: 320px;
    transition: all 0.5s ease;

    @media  (min-width: 800px) {
      padding: 32px;
      width: 420px;
    }

    .title {
      font-family: 'sofia pro bold', 'Roboto', Arial, Helvetica, sans-serif;
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 24px;
    }

    .caption {
      color: #777E92;
      font-size: 14px;
      font-weight: bold;
    }

    input[type="number"] {
      font-family: inherit;
      padding: 10px 16px 8px 16px;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #8B8FB1;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 8px 0;
      width: 90%;
    }
  }
}


h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.ecd {
  font-size: 14px;
  padding: 24px 8px 8px 8px;
  text-align: center;

  .finish-day {
    font-size: 50px;
    line-height: 54px;
    text-align: center;
    font-weight: bold;
    color: #015DF0FF;
    padding: 16px 0 0 0;
  }
}

.sprints {

  padding-bottom: 16px;

  .selectable-values {
    display: flex;
    margin: 16px 0;
    font-size: 16px;

    > div {
      flex: 1;
      text-align: center;
      padding: 12px;
      border: 1px solid #777E92;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.5s ease;

      &.selected {
        background-color: #015DF0FF;
        color: white;
      }

      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  select {
    background-color: white;
    font-size: inherit;
    font-family: inherit;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    border: 2px solid #444;
  }
}

.remaining {
  font-size: 20px;
  text-align: center;
}

.assigned-capacity {
  text-align: center;
}

footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 16px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: inherit;
  }

  img {
    height: 32px;
    padding: 0 8px;
  }
}
</style>
